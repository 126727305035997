import axios from "axios";

const baseURL = 'https://finopoly.newgensoft.com/api';
const SharedService = {

    createGame: async(req, headers) => {
        return axios.post(`${baseURL}/game/create`, req, headers);
    },

    getGameDetails: async (id, headers) => {
        return axios.get(`${baseURL}/game/details/${id}`, headers);
    },

    getQuestionsList: async (headers) => {
        return axios.get(`${baseURL}/game/questions`, headers);
    },

    confirmAnswer: async (req, headers) => {
        return axios.post(`${baseURL}/game/answer`, req, headers);
    },

    redeemPoints: async (req, headers) => {
        return axios.post(`${baseURL}/game/redeemPoints`, req, headers);
    },

    sendOTP: async (req) => {
        return axios.post(`${baseURL}/auth/sendOTP`, req);
    },

    verifyOTP: async (req) => {
        return axios.post(`${baseURL}/auth/verifyOTP`, req);
    },

    validateToken: async (req) => {
        return axios.get(`${baseURL}/auth/validateToken`);
    },

    registerUser: async (req) => {
        return axios.post(`${baseURL}/user/register`, req);
    },

    getUserDetails: async (id) => {
        return axios.get(`${baseURL}/user/details/${id}`);
    },

    updateUserDetails: async (id, req) => {
        return axios.put(`${baseURL}/user/details/${id}`, req);
    },

    getDashboardDetails: async (req) => {
        return axios.post(`${baseURL}/game/dashboard`, req);
    }
};
export default SharedService;
