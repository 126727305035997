import React from "react";
import { useEffect, useState, useRef } from "react";
import "./Board.scss";
const tiles = [
  { name: "Go", type: "corner" },
  { name: "Mediterranean Avenue", type: "property" },
  { name: "Community Chest", type: "special" },
  { name: "Baltic Avenue", type: "property" },
  { name: "Income Tax", type: "special" },
  { name: "Reading Railroad", type: "railroad" },
  { name: "Oriental Avenue", type: "property" },
  { name: "Chance", type: "special" },
  { name: "Vermont Avenue", type: "property" },
  { name: "Connecticut Avenue", type: "property" },
  { name: "Jail/Just Visiting", type: "corner" },
  { name: "St. Charles Place", type: "property" },
  { name: "Electric Company", type: "utility" },
  { name: "States Avenue", type: "property" },
  { name: "Virginia Avenue", type: "property" },
  { name: "Pennsylvania Railroad", type: "railroad" },
  { name: "St. James Place", type: "property" },
  { name: "Community Chest", type: "special" },
  { name: "Tennessee Avenue", type: "property" },
  { name: "New York Avenue", type: "property" },
  { name: "Free Parking", type: "corner" },
  { name: "Kentucky Avenue", type: "property" },
  { name: "Chance", type: "special" },
  { name: "Indiana Avenue", type: "property" },
  { name: "Illinois Avenue", type: "property" },
  { name: "B&O Railroad", type: "railroad" },
  { name: "Atlantic Avenue", type: "property" },
  { name: "Ventnor Avenue", type: "property" },
  { name: "Water Works", type: "utility" },
  { name: "Marvin Gardens", type: "property" },
  { name: "Go to Jail", type: "corner" },
  { name: "Pacific Avenue", type: "property" },
  { name: "North Carolina Avenue", type: "property" },
  { name: "Community Chest", type: "special" },
  { name: "Pennsylvania Avenue", type: "property" },
  { name: "Short Line Railroad", type: "railroad" },
  { name: "Chance", type: "special" },
  { name: "Park Place", type: "property" },
  { name: "Luxury Tax", type: "special" },
  { name: "Boardwalk", type: "property" },
];
export default function Board() {
  return (
    <div className="board">
      {tiles.map((tile, index) => (
        <div className={`tile ${tile.type}`}>
          <p>{tile.name}</p>
        </div>
      ))}
    </div>
  );
}
