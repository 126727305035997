export const gameBlocks = [
    { name: 'Legacy Lockup', index: 11, position: 'bottom' }, //corner
    { name: 'Compliance Courtyard', index: 10, position: 'bottom' },
    { name: 'Credit History Circle', index: 9, position: 'bottom' },
    { name: 'Question', index: 8, position: 'bottom' },
    { name: 'Onboarding Avenue', index: 7, position: 'bottom' },
    { name: 'Question', index: 6, position: 'bottom' },
    { name: 'Legacy Tax', index: 5, position: 'bottom' },
    { name: 'Paperwork Lane', index: 4, position: 'bottom' },
    { name: 'Question', index: 3, position: 'bottom' },
    { name: 'Signup Boulevard', index: 2, position: 'bottom' }, 
    { name: 'Go', index: 1, position: 'bottom' }, //corner
    { name: 'Go to Digital Transformation Jail', index: 20, position: 'left' }, 
    { name: 'Free Innovation Parking', index: 19, position: 'left' }, 
    { name: 'Question', index: 18, position: 'left' },
    { name: 'Biometric', index: 17, position: 'left' },
    { name: 'Question', index: 16, position: 'left' },
    { name: 'Credit Card Circle', index: 15, position: 'left' },
    { name: 'Underwriters Avenue', index: 14, position: 'left' },
    { name: 'Question', index: 13, position: 'left' }, 
    { name: 'Smart Capture', index: 12, position: 'left' },
    { name: 'Unified Platform', index: 21, position: 'top' }, //corner
    { name: 'Machine Learning Suite', index: 22, position: 'top' },
    { name: 'Question', index: 23, position: 'top' },
    { name: 'Data Security', index: 24, position: 'top' },
    { name: 'Onboarding Solutions', index: 25, position: 'top' },
    { name: 'Question', index: 26, position: 'top' },
    { name: 'Content Repository', index: 27, position: 'top' },
    { name: 'Document Capture', index: 28, position: 'top' }, 
    { name: 'Question', index: 29, position: 'top' },
    { name: 'Omnichannel Delivery', index: 30, position: 'top' },
    { name: 'Predictive Analytics', index: 31, position: 'top' }, //corner
    { name: 'Customer Service Tools', index: 32, position: 'right' },
    { name: 'Newgen Innovations', index: 33, position: 'right' },
    { name: 'Question', index: 34, position: 'right' },
    { name: 'Partnership Highlights', index: 35, position: 'right' },
    { name: 'Digital Transformation Jail', index: 36, position: 'right' },
    { name: 'Question', index: 37, position: 'right' },
    { name: 'Tech Trivia', index: 38, position: 'right' },
    { name: 'Question', index: 39, position: 'right' },
    { name: 'Tech Trivia 2', index: 40, position: 'right' }
  ];
  