import logo from './logo.svg';
import './App.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Route, Routes } from 'react-router-dom';
import Home from './Home.jsx';
import Game from './Game.tsx';
import Admin from './Admin.jsx';
import Board from './Board.jsx';


function App() {
  return (
    <Routes>
      <Route index path="/" element={<Home />} />
      <Route path='/game/:gameId' element={<Game />}></Route>
      <Route path="/admin" element={<Admin/>}></Route>
      <Route path="/board" element={<Board/>}></Route>
    </Routes>
    
  );
}

export default App;
