import React from "react";
import { useEffect, useState, useRef } from "react";
import { gameBlocks } from "./GameBlocks.js";
import "./Game.scss";
import {
  ModalHeader,
  ModalContent,
  ModalActions,
  Button,
  Modal,
} from "semantic-ui-react";
import { Icon, Image } from "semantic-ui-react";
import Dice from "react-dice-roll";
import SharedService from "./Shared.services.jsx";
import { FormField, Form, Radio } from "semantic-ui-react";
import { ToastContainer, toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import { Input } from 'semantic-ui-react';

export default function Game() {
  let gameBlocksList = gameBlocks;
  const [currentBlock, setCurrentBlock] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [dice1Value, setDice1Value] = useState(0);
  const [dice2Value, setDice2Value] = useState(0);
  const questionsList = useRef([]);
  const [updatedGameBlocks, setUpdatedGameBlocks] = useState([]);
  const answer = useRef("");
  const [redeemModalOpen, setRedeemModalOpen] = useState(false);
  const [isRedeemScreen, setIsRedeemScreen] = useState(true);
  const [confirmationEmail, setConfirmationEmail] = useState(
    localStorage.emailId
  );
  const [questionBlockDetails, setQuestionBlockDetails]: any = useState({});
  const [triggerUseEffect, setTriggerUseEffect] = useState(false);
  const [disableRollDiceBtn, setDisableRollDiceBtn] = useState(false);
  const [tokenPosition, setTokenPosition] = useState(1);
  const [showNoAccess, setShowNoAccess] = useState(false);

  const toastConfig: any = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };
  let questionCounter = 0;
  const [totalPoints, setTotalPoints] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [openGameStatusModal, setOpenGameStatusModal] = useState(false);
  const { gameId } = useParams();
  const correctlyAnsweredQuestions: any = useRef([]);
  const [gameDetails, setGameDetails]: any = useState({});
  const [cheatValue1, setCheatValue1]: any = useState(1);
  const [cheatValue2, setCheatValue2]: any = useState(1);
  const [showHowToGuide, setShowHowToGuide]: any = useState(false);
  const navigate = useNavigate();

  let headers = {
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.token}`,
      emailId: localStorage.emailId,
    },
  };

  const afterDiceRolled = () => {
    let number = dice1Value + dice2Value;
    let destination = currentBlock + number;
    if (40 - currentBlock >= number) {
      setCurrentBlock(destination);

      moveAvatar(currentBlock, destination, number);
    } else {
      let blocksLeft = 40 - currentBlock;
      let diff = number - blocksLeft;
      setCurrentBlock(40);

      moveAvatar(currentBlock, 40, number);

      setTimeout(() => {
        setCurrentBlock(diff);
        moveAvatar(1, diff, number);
      }, blocksLeft * 600);
    }
  };

  const moveAvatar = async (startIndex, targetIndex, diceTotal) => {
    if (startIndex === targetIndex) {
      return;
    }

    const gameContainer: any = document.getElementById("game-board");
    const gameContainerRect = gameContainer?.getBoundingClientRect();

    for (let i = startIndex + 1; i <= targetIndex; i++) {
      const targetBlock = document.getElementById(`game-block-${i}`);
      setTokenPosition(i);
      if (!targetBlock) {
        console.error(`Block with index ${i} not found. Skipping.`);
        continue;
      } else {
        if (i === targetIndex) {
          const gameBlock: any = updatedGameBlocks.filter((item: any) => {
            if (item.index === targetIndex) {
              return item;
            }
          })[0];
          if (gameBlock.name === "Question") {
            setTimeout(() => {
              openQuestionModal(gameBlock);
            }, 500);
          }
        }
      }
      const targetRect = targetBlock.getBoundingClientRect();
      const relativeTop = targetRect.top - gameContainerRect.top;
      const relativeLeft = targetRect.left - gameContainerRect.left;

      const avatar: any = document.querySelector(".avatar");
      if (avatar) {
        playSound("/move_avatar.m4a");
        avatar.style.top = `${relativeTop + 10}px`;
        avatar.style.left = `${relativeLeft + 10}px`;
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
    }
    setDisableRollDiceBtn(false);
  };

  const rollBothDice = (ev) => {
    setDisableRollDiceBtn(true);
    if (gameDetails.status !== "Finished" && !gameDetails.pointsRedeemed) {
      ev.preventDefault();
      ev.stopPropagation();
      const diceEl: any = document.querySelectorAll("._space3d");

      if (diceEl.length !== 0) {
        for (let i = 0; i < diceEl.length; i++) {
          diceEl[i].click();
        }
      }
    } else {
      setDisableRollDiceBtn(false);
      playSound("/Game-complete.mp3");
      setOpenGameStatusModal(true);
    }
  };

  const manipulateDice = () => {
    const targetSum = Math.floor(Math.random() * 4) + 2;
    let dice1 = 0;
    let dice2 = 0;
    if (targetSum === 2) {
      dice1 = 1;
      dice2 = 1;
    } else if (targetSum === 3) {
      dice1 = 1;
      dice2 = 2;
    } else if (targetSum === 5) {
      const options = [
        [1, 4],
        [2, 3],
        [3, 2],
        [4, 1],
      ];
      const chosenOption = options[Math.floor(Math.random() * options.length)];
      dice1 = chosenOption[0];
      dice2 = chosenOption[1];
    }
    setCheatValue1(dice1);
    setCheatValue2(dice2);
  };

  useEffect(() => {
    afterDiceRolled();
    manipulateDice();
  }, [triggerUseEffect]);

  const getGameDetails = () => {
    SharedService.getGameDetails(gameId, headers)
      .then((response) => {
        if (response.data) {
          setGameDetails(response.data.data);
          if (response.data?.data.status === "Finished") {
            playSound("/Game-complete.mp3");
            setOpenGameStatusModal(true);
          } else {
            setOpenGameStatusModal(false);
          }
          setTotalPoints(response.data.data.points);

          correctlyAnsweredQuestions.current = Object.entries(
            response.data?.data.questionLogs
          )
            .filter(
              ([key, value]: any) =>
                value.status === "Correct" || value.tries === 3
            )
            .map(([key, value]: any) => ({
              questionId: key,
              status: value.status,
              tries: value.tries,
            }));
        }
      })
      .catch((error) => {
        if(error.response.data.errorCode === '3002'){
          setShowNoAccess(true);
        }
        else if(error.response.data.errorCode === '2005' || error.response.data.errorCode === '2006' || error.response.data.errorCode === '2007'){
          navigate(`../`);
        }
        else{
          console.log("Could not fetch game details");
        }
      });
  };

  const getQuestions = () => {
    SharedService.getQuestionsList(headers)
      .then((response) => {
        questionsList.current = response.data.data.questionsList;
        const updatedGameBlocks: any = gameBlocksList.map((block) => {
          if (
            block.name === "Question" &&
            questionCounter < questionsList.current.length
          ) {
            const question: any = questionsList.current[questionCounter];
            questionCounter++;
            return {
              ...block,
              name: `Question`,
              question: question.question,
              options: question.options,
              questionId: question._id,
              link: question.link,
            };
          }
          return block;
        });

        setUpdatedGameBlocks(updatedGameBlocks);
      })
      .catch((error) => {
        console.log("Could not fetch questions");
      });
  };

  useEffect(() => {
    getGameDetails();
    getQuestions();
    setTimeout(() => {
      let el = document.getElementById("roll-dice-btn");
      if (el) {
        el.focus();
      }
    }, 100);
  }, []);

  const openQuestionModal = (block) => {
    if (block.name === "Question") {
      setQuestionBlockDetails(block);
      let index = correctlyAnsweredQuestions.current.findIndex(
        (x) => x.questionId === block.questionId
      );
      if (index === -1) {
        playSound("/question.mp3");
        setModalOpen(true);
      } else {
        if (correctlyAnsweredQuestions.current[index].tries === 3) {
          playSound("/incorrect.mp3");
          toast(
            "Oops! No more tries left for this question. Roll the dice.",
            toastConfig
          );
        } else {
          playSound("/correct.mp3");
          toast("Yay! Correct answer given already.", toastConfig);
        }
      }
    }
  };

  const selectAnswer = (value) => {
    answer.current = value;
  };

  const confirmAnswer = () => {
    let req = {
      questionId: questionBlockDetails.questionId,
      answer: answer.current,
      gameId: gameId,
    };
    if (
      answer.current === undefined ||
      answer.current === null ||
      answer.current === ""
    ) {
      toast(
        "Please select an option before submitting the answer.",
        toastConfig
      );
      return false;
    }
    SharedService.confirmAnswer(req, headers)
      .then((response) => {
        if (response.data) {
          setTotalPoints(response.data.data.gameDetails.points);
          setGameDetails(response.data.data.gameDetails);
          if (response.data.message === "Tries over for this question") {
            correctlyAnsweredQuestions.current.push({
              questionId: questionBlockDetails.questionId,
              status: "In-correct",
              tries: 3,
            });
            playSound("/incorrect.mp3");
            toast(
              "Oops! No more tries left for this question. Roll the dice.",
              toastConfig
            );
            setModalOpen(false);
            return false;
          }
          if (response.data.message === "Answer is in-correct") {
            playSound("/incorrect.mp3");
            toast("Oops! You’ve landed in Jail! Try Again.", toastConfig);
            setModalOpen(true);
          } else {
            correctlyAnsweredQuestions.current.push({
              questionId: questionBlockDetails.questionId,
              status: "Correct",
              tries: 1,
            });
            playSound("/correct.mp3");
            setModalOpen(false);
            toast("Advancing on the board—keep it up!", toastConfig);
          }
        }
      })
      .catch((error) => {
        if(error.response.data.errorCode === '3002'){
          setShowNoAccess(true);
        }
        else if(error.response.data.errorCode === '2005' || error.response.data.errorCode === '2006' || error.response.data.errorCode === '2007'){
          navigate(`../`);
        }
        else{
          console.log(error + "Unable to confirm answer");
        }        
        setModalOpen(false);
      });
  };

  const redeemNow = () => {
    let req = {
      gameId: gameId,
    };
    SharedService.redeemPoints(req, headers)
      .then((response) => {
        if (response.data) {
          setTotalPoints(response.data.data.gameDetails.points);
          playSound("/Game-complete.mp3");
          setGameOver(true);
        }
      })
      .catch((error) => {
        if(error.response.data.errorCode === '3002'){
          setShowNoAccess(true);
        }
        else if(error.response.data.errorCode === '2005' || error.response.data.errorCode === '2006' || error.response.data.errorCode === '2007'){
          navigate(`../`);
        }
        else{
          toast("Could not verify email. Please try again.", toastConfig);
        }
      });
  };

  const verifyEmail = () => {
    if (confirmationEmail === localStorage.emailId) {
      redeemNow();
    } else {
      toast("Email does not match", toastConfig);
    }
  };

  const checkGameStatus = () => {
    if (gameDetails.status != "Finished" && !gameDetails.pointsRedeemed) {
      if (totalPoints < 12) {
        toast(
          "Oops, you do not have enough points to redeem them.",
          toastConfig
        );
        return false;
      } else {
        setRedeemModalOpen(true);
      }
    } else {
      if (gameDetails.pointsRedeemed) {
        playSound("/Game-complete.mp3");
        setOpenGameStatusModal(true);
      } else {
        setRedeemModalOpen(true);
      }
    }
  };

  const checkVisitedQuestionStatus = (block) => {
    if (block.name === "Question") {
      let index = correctlyAnsweredQuestions.current.findIndex(
        (x) => x.questionId === block.questionId
      );
      if (index !== -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const playSound = (url) => {
    const sound = new Audio(url);
    sound.pause();
    sound.currentTime = 0;
    sound.play().catch((error) => {
      console.error("Error playing sound:", error);
    });
  };

  return (
    <div className="game-body">
      <div className="left-side">
        <div className="header">
          <Image src="/logo-newgen.svg" wrapped ui={false} />
          <span className="gameTitle">FINOPOLY</span>
        </div>
        <div className="how-to-container">
          <div className="guide">
            <div className="userSection">
              <h3>
                Ahoy <em>{localStorage.emailId},</em> welcome to Finopoly!{" "}
              </h3>
              <h3>
                Your current points are: <em>{totalPoints}</em>
              </h3>
              <div className="btn-container">
                <button
                  className="secondary-btn"
                  onClick={() => checkGameStatus()}
                >
                  Redeem Now!
                </button>
                <button
                  className="primary-btn"
                  onClick={(ev) => rollBothDice(ev)}
                  disabled={disableRollDiceBtn}
                  id="roll-dice-btn"
                >
                  Roll Dice
                </button>
                <button
                  className="how-guide-btn"
                  onClick={() => setShowHowToGuide(true)}
                >
                  <Icon className="how-to-icon" name="help" />
                </button>
              </div>
            </div>
            <div className="howToPlayGuideContainer">
              <h4>The Finopoly Rule Book:</h4>
              <div>
                <ul>
                  <li>
                    <b>Quest of Questions:</b> Answer a total of 12
                    multiple-choice questions. Each correct answer earns you 2
                    points.
                  </li>
                  <li>
                    <b>The Point System:</b> You need to score at least 12
                    points or answer 6 questions correctly to be eligible for a
                    voucher.
                  </li>
                  <li>
                    <b>Eyes on the Prize:</b> Each point translates into a
                    dollar in your voucher, and you can win up to a maximum of
                    $24.
                  </li>
                  <li>
                    <b>The Safety Net:</b> After reaching the 12-point
                    threshold, you will secure a $12 voucher. You will keep
                    adding $2 to the voucher for every correct answer.
                  </li>
                  <li>
                    <b>Watch Your Step:</b> Every wrong answer sends you
                    straight to jail, costing you 2 points for that question.
                    Ouch!
                  </li>
                  <li>
                    <b>Every Question Counts:</b> If you fail to win 12 points,
                    you’ll be declared bankrupt. It’s a tough world out there
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="right-side">
        <div className="game-container">
          <div id="game-board" className="game-board">
            <div className={`top-row`}>
              {updatedGameBlocks.map((block: any, i) => {
                if (block.position === "top") {
                  return (
                    <div
                      key={i}
                      id={`game-block-${block.index}`}
                      className={`game-block ${
                        block.index === 1 ||
                        block.index === 11 ||
                        block.index === 21 ||
                        block.index === 31
                          ? "corner-block"
                          : ""
                      } ${
                        block.name === "Question" &&
                        checkVisitedQuestionStatus(block)
                          ? "visited-question-border"
                          : ""
                      }
                      ${
                        block.name === "Question" &&
                        !checkVisitedQuestionStatus(block)
                          ? "blinking-border"
                          : ""
                      }
                      ${block.index === tokenPosition ? "token" : ""}`}
                    ></div>
                  );
                }
              })}
            </div>
            <div className="middle-row">
              <div className="left-col">
                {updatedGameBlocks.map((block: any, i) => {
                  if (block.position === "left") {
                    return (
                      <div
                        key={i}
                        id={`game-block-${block.index}`}
                        className={`game-block ${
                          block.name === "Question" &&
                          checkVisitedQuestionStatus(block)
                            ? "visited-question-border"
                            : ""
                        }
                      ${
                        block.name === "Question" &&
                        !checkVisitedQuestionStatus(block)
                          ? "blinking-border"
                          : ""
                      }                      
                      ${block.index == tokenPosition ? "token" : ""}`}
                      ></div>
                    );
                  }
                })}
              </div>
              <div className="center-col"></div>
              <div className="right-col">
                {updatedGameBlocks.map((block: any, i) => {
                  if (block.position === "right") {
                    return (
                      <div
                        key={i}
                        id={`game-block-${block.index}`}
                        className={`game-block ${
                          block.name === "Question" &&
                          checkVisitedQuestionStatus(block)
                            ? "visited-question-border"
                            : ""
                        }
                      ${
                        block.name === "Question" &&
                        !checkVisitedQuestionStatus(block)
                          ? "blinking-border"
                          : ""
                      }
                      ${block.index == tokenPosition ? "token" : ""}`}
                      ></div>
                    );
                  }
                })}
              </div>
            </div>
            <div className="bottom-row">
              {updatedGameBlocks.map((block: any, i) => {
                if (block.position === "bottom") {
                  return (
                    <div
                      key={i}
                      id={`game-block-${block.index}`}
                      className={`game-block ${
                        block.index === 1 ||
                        block.index === 11 ||
                        block.index === 21 ||
                        block.index === 31
                          ? "corner-block"
                          : ""
                      } ${
                        block.name === "Question" &&
                        checkVisitedQuestionStatus(block)
                          ? "visited-question-border"
                          : ""
                      }
                      ${
                        block.name === "Question" &&
                        !checkVisitedQuestionStatus(block)
                          ? "blinking-border"
                          : ""
                      }
                      ${block.index == tokenPosition ? "token" : ""}`}
                    ></div>
                  );
                }
              })}
            </div>

            <div className="avatar">
              <div className="avatarImg"></div>
            </div>

            {/* Question Modal  */}
            <Modal
              size={"small"}
              open={modalOpen}
              onClose={() => setModalOpen(false)}
            >
              <ModalHeader>{questionBlockDetails?.question}</ModalHeader>
              <ModalContent>
                <Form className="question-form">
                  {questionBlockDetails?.options?.map((x) => {
                    return (
                      <FormField key={x.key}>
                        <Radio
                          label={x.value}
                          name="radioGroup"
                          value={x.value}
                          onChange={() => selectAnswer(x.key)}
                        />
                      </FormField>
                    );
                  })}
                </Form>
              </ModalContent>
              <ModalActions>
                {questionBlockDetails.link && (
                  <a href={questionBlockDetails.link} target="_blank">
                    <Icon className="product-info" size="big" name="info circle"/>
                  </a>
                )}
                <Button negative onClick={() => setModalOpen(false)}>
                  Cancel
                </Button>
                <Button positive onClick={() => confirmAnswer()}>
                  Submit
                </Button>
              </ModalActions>
            </Modal>

            {/* Dice */}
            <div className="dice-area">
              <Dice
                rollingTime={100}
                size={50}
                triggers={["click"]}
                onRoll={(ev) => {
                  playSound("/Dice roll.wav");
                  setDice1Value(ev);
                }}
                cheatValue={cheatValue1}
              />
              <Dice
                rollingTime={200}
                size={50}
                triggers={["click"]}
                onRoll={(ev) => {
                  setTimeout(() => {
                    setDice2Value(ev);
                    setTriggerUseEffect(!triggerUseEffect);
                  }, 200);
                }}
                cheatValue={cheatValue2}
              />
            </div>

            {/* Redeem Points Modal */}
            <Modal
              size={"tiny"}
              open={redeemModalOpen}
              onClose={() => setRedeemModalOpen(false)}
            >
              <ModalHeader>Redeem Points</ModalHeader>
              <ModalContent>
                {isRedeemScreen && (
                  <>
                    Your score is: {totalPoints}
                    <br />
                    Are you sure you want to redeem these points?
                  </>
                )}
                {!isRedeemScreen && !gameOver && (
                  <div className="confirm-email">
                    Please confirm your email and select the voucher:
                    <Input 
                      type="email"
                      placeholder="Enter your work email address"
                      value={confirmationEmail}
                      onChange={(e) => setConfirmationEmail(e.target.value)}
                    />
                  </div>
                )}
                {gameOver && (
                  <>
                    Congratulations! Your e-gift voucher is ready.
                    <br />
                    Your voucher will be sent to your registered email within 48 hours.
                  </>
                )}
              </ModalContent>
              <ModalActions>
                {isRedeemScreen && (
                  <>
                    <Button negative onClick={() => setRedeemModalOpen(false)}>
                      Cancel
                    </Button>
                    <Button positive onClick={() => setIsRedeemScreen(false)}>
                      Proceed
                    </Button>
                  </>
                )}
                {!isRedeemScreen && !gameOver && (
                  <>
                    <Button negative onClick={() => setRedeemModalOpen(false)}>
                      Cancel
                    </Button>
                    <Button positive onClick={() => verifyEmail()}>
                      Redeem
                    </Button>
                  </>
                )}
                {gameOver && (
                  <>
                    <Button positive onClick={() => {setRedeemModalOpen(false); getGameDetails()}}>
                      OK
                    </Button>
                  </>
                )}
              </ModalActions>
            </Modal>

            {/* Game Status Modal */}
            <Modal size={"small"} open={openGameStatusModal}>
              <ModalContent>
                <div className="game-status-content">
                  <Image className="gameOverGraphics" src="/game-over.svg" />
                  Woohoo... You have completed this game!
                </div>
              </ModalContent>
              {!gameDetails.pointsRedeemed && (
                <ModalActions>
                  <Button
                    negative
                    onClick={() => setOpenGameStatusModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    positive
                    onClick={() => {
                      setOpenGameStatusModal(false);
                      setRedeemModalOpen(true);
                    }}
                  >
                    Redeem Points
                  </Button>
                </ModalActions>
              )}
            </Modal>

            {/* How to play guide modal */}
            <Modal size={"small"} open={showHowToGuide}>
              <ModalHeader>The Finopoly Rule Book:</ModalHeader>
              <ModalContent>
                <div>
                  <ul>
                    <li>
                      <b>Quest of Questions:</b> Answer a total of 12
                      multiple-choice questions. Each correct answer earns you 2
                      points.
                    </li>
                    <li>
                      <b>The Point System:</b> You need to score at least 12
                      points or answer 6 questions correctly to be eligible for a
                      voucher.
                    </li>
                    <li>
                      <b>Eyes on the Prize:</b> Each point translates into a
                      dollar in your voucher, and you can win up to a maximum of
                      $24.
                    </li>
                    <li>
                      <b>The Safety Net:</b> After reaching the 12-point
                      threshold, you will secure a $12 voucher. You will keep
                      adding $2 to the voucher for every correct answer.
                    </li>
                    <li>
                      <b>Watch Your Step:</b> Every wrong answer sends you
                      straight to jail, costing you 2 points for that question.
                      Ouch!
                    </li>
                    <li>
                      <b>Every Question Counts:</b> If you fail to win 12 points,
                      you’ll be declared bankrupt. It’s a tough world out there
                    </li>
                  </ul>
                </div>
              </ModalContent>

              <ModalActions>
                <Button
                  positive
                  onClick={() => {
                    setShowHowToGuide(false);
                  }}
                >
                  Close
                </Button>
              </ModalActions>
            </Modal>

            {/* No Access to game Modal */}
            <Modal size={"small"} open={showNoAccess} closeOnDimmerClick={false} closeOnEscape={false} >
              <ModalContent>
                <div className="game-status-content">
                  <Image className="gameOverGraphics" src="/forbidden.svg" />
                  Oops! You do not have access to this game.
                </div>
              </ModalContent>
            </Modal>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}
