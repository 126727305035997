import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Image } from "semantic-ui-react";
import "./Home.scss";
import SharedService from "./Shared.services.jsx";
import { ToastContainer, toast } from "react-toastify";
function Home() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState(new Array(6).fill("")); // Array for 6 OTP digits
  const [showOtpInput, setShowOtpInput] = useState(false);
  const inputRefs = useRef([]);
  const [isChecked, setIsChecked] = useState(false);
  const toastConfig = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    cssEase: "linear",
  };
  const videoRef = useRef(null);

  // useEffect(() => {
  //   const video = videoRef.current;

  //   if (video) {
  //     video.addEventListener('canplay', () => {
  //       setTimeout(()=>{
  //         video.muted = false;

  //       },3000)
  //     });
  //   }

  //   return () => {
  //     if (video) {
  //       video.removeEventListener('loadeddata', () => {
  //         setTimeout(()=>{
  //           video.muted = false;

  //         },1000)
  //       });
  //     }
  //   };
  // }, []);

  const handleEmailSubmit = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if(!isChecked){
      toast("Kindly select the checkbox to proceed ahead.", toastConfig);
      return false;
    }
    if (!emailRegex.test(email)) {
      toast("Enter a valid email address", toastConfig);
    } else {
      const req = {
        emailId: email,
        nickName: email.split("@")[0],
        avatar: "Default",
      };

      SharedService.registerUser(req)
        .then((response) => {
          sendOTP(email);
        })
        .catch((error) => {
          sendOTP(email);
        });
    }
  };

  const sendOTP = (emailId) => {
    const req = {
      emailId: emailId,
    };
    SharedService.sendOTP(req).then((response) => {
      toast("OTP sent successfully to your email.", toastConfig);
      setShowOtpInput(true);
      setTimeout(() => {
        inputRefs.current[0].focus();
      }, 500);
    });
  };

  const verifyOTP = () => {
    const req = {
      emailId: email,
      otp: parseInt(otp.join("")),
    };
    SharedService.verifyOTP(req)
      .then((response) => {
        if (response.data != undefined) {
          toast("OTP verified successfully.", toastConfig);
          sessionStorage.setItem("token", response.data.data.token);
          sessionStorage.setItem("emailId", response.data.data.emailId);
          sessionStorage.setItem("userId", response.data.data.userId);
          sessionStorage.setItem("nickName", response.data.data.nickName);
          sessionStorage.setItem("avatar", response.data.data.avatar);
          localStorage.setItem("emailId", response.data.data.emailId);
          localStorage.setItem("token", response.data.data.token);
          createNewGame();
        }
      })
      .catch((error) => {
        console.log(error);
        toast("Incorrect OTP. Please try again.", toastConfig);
      });
  };

  const handleOtpChange = (element, index) => {
    if (/^[0-9]$/.test(element.value)) {
      // Ensure only digits are allowed
      let newOtp = [...otp];
      newOtp[index] = element.value;
      setOtp(newOtp);

      // Move focus to the next input box if available
      if (index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key !== "Enter") {
      const newOtp = [...otp];

      // Clear current input
      newOtp[index] = "";
      setOtp(newOtp);

      if (e.key === "Backspace" && index > 0 && !otp[index]) {
        inputRefs.current[index - 1].focus(); // Move to the previous input on backspace
      }
    } else {
      handlePlay();
    }
  };

  const handlePlay = () => {
    verifyOTP();
  };

  const createNewGame = () => {
    const req = {
      emailId: email,
    };
    const headers = {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
        emailId: sessionStorage.emailId,
      },
    };
    let responseData = {};
    SharedService.createGame(req, headers)
      .then((response) => {
        if (response.data != undefined) {
          responseData = response.data.data;
          const gameId = responseData.gameId;
          navigate(`/game/${gameId}`);
        }
      })
      .catch((error) => {
        console.log(error);
        const gameId = error.response.data.data._id;
        navigate(`/game/${gameId}`);
      });
  };

  const handleEmailSubmitonEnter = (e) => {
    if (e.key === "Enter") {
      handleEmailSubmit();
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    console.log(isChecked);
  };

  return (
    <>
      {/* <div className="loadingScreen">
    <div className="loadingWrapper"> 
      <div className="loadingAnimation">
      <Image src="/loading-animation.svg"/>

      </div>
    </div>

    </div> */}
      <div className="landing-page">
        <div className="header">
          <Image src="/logo-newgen.svg" wrapped ui={false} />
        </div>
        <div className="videoWrapper">
          <video
            id="bg-video"
            className="background-video"
            autoPlay
            loop
            muted
            ref={videoRef}
          >
            <source src="/video.mp4" type="video/mp4" />
          </video>
        </div>

        <div className="content-wrapper">
          <div className="right-section">
            <h1 className="title">
              Are you ready to play <strong>FINOPOLY?</strong>
            </h1>
            {!showOtpInput && (
              <>
                <p className="subtitle">
                  Enter your work email to get started!
                </p>

                <input
                  type="email"
                  placeholder="Enter your work email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyDown={(e) => handleEmailSubmitonEnter(e)}
                  className="email-input-box"
                />
                <label className="checkbox-wrapper">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    className="styled-checkbox"
                    onChange={handleCheckboxChange}
                  />
                  <em>Check this box & our Marketing Maestros will keep you updated on the best in Tech for Finance</em>
                </label>
              </>
            )}

            {!showOtpInput && (
              <button onClick={handleEmailSubmit} className="proceed-button">
                Proceed
              </button>
            )}

            {showOtpInput && (
              <>
                <p className="subtitle">Enter OTP</p>
                <div className="otp-input-wrapper">
                  {otp.map((digit, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength="1"
                      value={digit}
                      className="otp-box"
                      onChange={(e) => handleOtpChange(e.target, index)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      ref={(el) => (inputRefs.current[index] = el)} // Ref for focus handling
                    />
                  ))}
                </div>
                <div className="resend-otp">
                  <a href="javascript:void(0)" onClick={() => sendOTP()}>
                    Resend OTP
                  </a>
                </div>
                <button onClick={handlePlay} className="play-button">
                  Let's Play
                </button>
              </>
            )}
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}

export default Home;
