import React from "react";
import { useState, useRef, useEffect } from "react";
import { Image } from "semantic-ui-react";
import { CSVLink, CSVDownload } from "react-csv";
import './Admin.scss';
import {
    ModalHeader,
    ModalContent,
    ModalActions,
    Button,
    Modal,
} from "semantic-ui-react";

import {
    TableRow,
    TableHeaderCell,
    TableHeader,
    TableCell,
    TableBody,
    Table
} from 'semantic-ui-react'
import SharedService from "./Shared.services";
import { ToastContainer, toast } from "react-toastify";
import { Input } from 'semantic-ui-react';


export default function Admin() {

    const headers = [
        { label: "S.No.", key: "sno" },
        { label: "Email", key: "emailId" },
        { label: "Questions Remaining", key: "questionsRemaining" },
        { label: "Points", key: "points" },
        { label: "Game Creation Time", key: "creationDateTime"},
        { label: "Game ID", key: "_id" },
        { label: "Game Status", key: "status" },
        { label: "Game Last Activity Time", key: "lastModifiedDateTime"},
        { label: "Points Redeemed", key: "pointsRedeemed" }
    ];

    const [data, setData] = useState([]);
    const [modalOpen, setModalOpen] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showVerifyOTPBtn, setVerifyOTPBtn] = useState(false);
    const [eyeIcon, setEyeIcon] = useState('eye');
    const toastConfig = {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    };

    const getDashboardDetails = () => {
        let request = {
            emailId: email,
            password: btoa(password)
        }
        SharedService.getDashboardDetails(request).then((response) => {
            if (response.data) {
                let data = response.data.data.map((item, index) => {
                    item.creationDateTime = new Date(item.creationDateTime).toGMTString();
                    item.lastModifiedDateTime = new Date(item.lastModifiedDateTime).toGMTString();
                    item.sno = index + 1;
                    return item;
                })
                setData(data);
                setModalOpen(false);
            }
        })
            .catch((error) => {
                console.log(error);
                toast("Enter correct username and password", toastConfig);
            })
    };

    const showPassword = () => {
        console.log(password);
        let el = document.getElementById('password-input');
        if(el){
            if (el.type === "password") {
                el.type = "text";
                setEyeIcon('eye slash')
            } else {
                el.type = "password";
                setEyeIcon('eye');
            }
        }
    };

    const handleOnEnter = (e) => {
        if (e.key === "Enter") {
            getDashboardDetails();
        }
    };

    return (
        <div className="admin-container">
            <div className="header">
                <Image src="/logo-newgen.svg" wrapped ui={false} />
            </div>
            <div className="export-btn-container">
                <button className="export-btn">
                    <CSVLink data={data} headers={headers}>
                        Export to CSV
                    </CSVLink>
                </button>
            </div>

            <div className="table-container">
                <Table celled unstackable>
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell>S.No</TableHeaderCell>
                            <TableHeaderCell>Email</TableHeaderCell>
                            <TableHeaderCell>Questions Remaining</TableHeaderCell>
                            <TableHeaderCell>Points</TableHeaderCell>
                            <TableHeaderCell>Game Creation Time</TableHeaderCell>
                            <TableHeaderCell>Game ID</TableHeaderCell>
                            <TableHeaderCell>Game Status</TableHeaderCell>
                            <TableHeaderCell>Game Last Activity Time</TableHeaderCell>
                            <TableHeaderCell>Points Redeemed</TableHeaderCell>
                        </TableRow>
                    </TableHeader>

                    <TableBody className="table-body">
                        {
                            data && data.map((item) => {
                                return <>
                                    <TableRow>
                                        <TableCell>{item.sno}</TableCell>
                                        <TableCell>{item.emailId}</TableCell>
                                        <TableCell>{item.questionsRemaining}</TableCell>
                                        <TableCell>{item.points}</TableCell>
                                        <TableCell>{item.creationDateTime}</TableCell>
                                        <TableCell>{item._id}</TableCell>
                                        <TableCell>{item.status}</TableCell>
                                        <TableCell>{item.lastModifiedDateTime}</TableCell>
                                        <TableCell>{item.pointsRedeemed ? 'Yes' : 'No'}</TableCell>
                                    </TableRow>
                                </>
                            })
                        }
                    </TableBody>
                </Table>
            </div>

            {/* Admin Login Modal */}
            <Modal
              size={"tiny"}
              open={modalOpen}
              onClose={() => setModalOpen(false)}
              closeOnEscape={false}
              closeOnDimmerClick={false}
            >
              <ModalHeader>Admin Login</ModalHeader>
              <ModalContent className="login-modal">
                <div className="login-modal-div">
                    <label>Enter admin email:</label>
                    <Input type="email" placeholder='Enter your admin email address' 
                       value={email}
                       onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                
                <div className="login-modal-div">
                    <label>Enter Password:</label>
                    <Input type="password" placeholder='Enter your password' 
                       value={password}
                       id='password-input'
                       onChange={(e) => setPassword(e.target.value)}
                       action={{
                        color: 'white',
                        labelPosition: 'right',
                        icon: eyeIcon,
                        onClick: () => showPassword()
                      }}
                      onKeyDown={ (e) => handleOnEnter(e)}
                    />
                </div>
              </ModalContent>
              <ModalActions>
                <Button positive onClick={getDashboardDetails}>
                    Submit
                </Button>
               </ModalActions>
            </Modal>

            <ToastContainer />
        </div>
    )
};